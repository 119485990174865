.pagination {
  display: flex;
  flex-direction: row;
  flex: 1 1 100%;
  align-items: center;
  justify-content: space-evenly; }
  .pagination button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit; }
  .pagination .next, .pagination .prev {
    flex: 0 1 auto; }
    .pagination .next svg, .pagination .prev svg {
      height: 2rem;
      width: 2rem; }
